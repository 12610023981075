<template>
  <div>
	<button @click="downloadPDf">downloadPDf</button>

		<pdf refs="pdf"
			v-for="i in numPages"
			:key="i"
			:src="src"
			:page="i"
			style="display: inline-block; width: 85%; margin-left: 8%;"
		></pdf>
	</div>
</template>

 
<script>
import Vue from 'vue';
import pdf from 'vue-pdf'
import axios from 'axios';

var loadingTask = pdf.createLoadingTask('../ELIN_CATALOGUE_2021_DIGITAL_SPREADS.pdf');

export default {
	components: {
		pdf
	},
	data() {
		return {
			src: loadingTask,
			numPages: 0,
		}
	},
	mounted() {

		this.src.promise.then(pdf => {

			this.numPages = pdf.numPages;
		});
	},
  methods:{
    downloadPDf() {
			axios.post('http://localhost:8080/pdf/ELIN_CATALOGUE_2021_DIGITAL_SPREADS.pdf', {
			responseType: 'blob', //important
			}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			let fname = 'report.pdf';
			link.href = url;
			link.setAttribute('download', fname);
			document.body.appendChild(link);
			link.click();
			})
		}
    }
}
</script>